export type KnowledgeOrdering =
  | 'Ideas'
  | 'Nature'
  | 'Cohesion'
  | 'Space'
  | 'Dossiers'
  | 'Digital'
export type CrossareaColorClass =
  | 'text'
  | 'bg'
  | 'scrollbar'
  | 'scrollbar-thumb'
  | 'hover:scrollbar-thumb'
  | 'border'

/**
 * Get ordering color
 * @param ordering
 * @param tailwindClass
 * @param opacity
 */
export function useOrderingColors(
  ordering: KnowledgeOrdering,
  tailwindClass: CrossareaColorClass,
  opacity?: number,
) {
  if (ordering && tailwindClass) {
    return `${tailwindClass}-${ordering.toLowerCase()}${
      opacity ? `/${opacity}` : ''
    }`
  }
  return ''
}
